import { useIdentity, useLogger } from "@vp/ubik-context";
import React, { JSX, PropsWithChildren, useContext } from "react";
import { useTimeout } from "../hooks/timeout";

export type AuthContext = {
  customerId: string;
  authorizationHeader: string;
};

export const authContext = React.createContext<AuthContext | undefined>(
  undefined,
);

export type AuthContextProviderProps = {
  customerId?: string;
};

export function AuthContextProvider(
  props: PropsWithChildren<AuthContextProviderProps>,
): JSX.Element {
  const identity = useIdentity();
  const logger = useLogger();

  const { customerId, authorizationHeader } = extractAuth(identity);

  return (
    <authContext.Provider
      value={{
        customerId:
          props.customerId || customerId || "S1IDG5GJUH4SW3Q346VOX6KK0OT50EO5",
        authorizationHeader: "",
      }}
    >
      {props.children}
    </authContext.Provider>
  );
}

export const AuthContextConsumer = authContext.Consumer;

export function useAuthContext(): AuthContext {
  const context = useContext(authContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used with AuthContextProvider");
  }
  return context;
}

function extractAuth(identity: ReturnType<typeof useIdentity>) {
  return {
    customerId: identity.auth?.getCanonicalId(),
    authorizationHeader: identity.auth?.getAuthorizationHeader(),
  };
}
