const baseUrl = "https://cdn.studio-available-routes-service.design.vpsvc.com";

export async function getStudioUrl(
  locale: string,
  productKey: string,
  productVersion: number,
  options: Record<string, string>,
): Promise<string> {
  const url = new URL(`${baseUrl}/v1/studioRouting/studio/path`);
  url.searchParams.append("locale", locale);
  url.searchParams.append("productKey", productKey);
  url.searchParams.append("productVersion", productVersion.toString());
  url.searchParams.append("selectedOptions", JSON.stringify(options));
  url.searchParams.append("requestor", "recommendations-stack");

  const response = await fetch(url.toString());

  return response.ok
    ? (await response.json()).url.pathWithLocale
    : Promise.reject(response);
}
