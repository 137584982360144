import {
  EditInStudioLink,
  OfferTileLinkProps,
  OfferTilePriceProps,
  StartingAtPrice,
  withLinkTile,
} from "../../../recommendations-offer-tiles-common";
import { Offer } from "../../../recommendations-types";

type MustEditInStudioOfferTileCtaProps = {
  offer: Offer;
  skin: "cta" | "primary";
};

function MustEditInStudioOfferTileCta(
  props: MustEditInStudioOfferTileCtaProps,
) {
  return <EditInStudioLink offer={props.offer} skin={props.skin} />;
}

export const MustEditInStudioOfferTile = withLinkTile(
  "MustEditInStudioOfferTile",
  {
    Link: (props: OfferTileLinkProps) => (
      <MustEditInStudioOfferTileCta {...props} skin="cta" />
    ),
    Location: "bottom",
  },
  (props: OfferTilePriceProps) => <StartingAtPrice {...props} />,
);

// TODO: Remove once t-shirts are supported in cross-sell studio
export const MustEditInStudioButtonOfferTile = withLinkTile(
  "MustEditInStudioButtonOfferTile",
  {
    Link: (props: OfferTileLinkProps) => (
      <MustEditInStudioOfferTileCta {...props} skin="primary" />
    ),
    Location: "bottom",
  },
  (props: OfferTilePriceProps) => <StartingAtPrice {...props} />,
);
