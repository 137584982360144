import { LocalizedOption } from "../../recommendations-types";

export const getLocalizedValue = (
  key: string,
  value: string,
  localizedOptions: { [key: string]: LocalizedOption },
): string => {
  return localizedOptions && localizedOptions[key]
    ? localizedOptions[key].values[value] || value
    : value;
};
