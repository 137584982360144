import { Icon } from "@vp/swan";

interface OfferTileImageOverlayProps {
  text: string;
}

export const OfferTileImageOverlay = (
  props: OfferTileImageOverlayProps
): JSX.Element => {
  return (
    <div
      style={{
        opacity: 1,
        position: "absolute",
        bottom: 0,
        background: "rgba(0, 0, 0, 0.7)",
        width: "100%",
        height: "100%",
        textAlign: "center",
      }}
    >
      <style>
        {`
      .added-check-icon::before {
        height: 40px;
        width: 40px;
        background-size: contain;
      }
      `}
      </style>
      <Icon
        className="added-check-icon"
        iconType="check"
        aria-hidden="true"
        skin="white"
        size="24p"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <span
        style={{
          position: "relative",
          top: "58%",
          color: "white",
          fontWeight: "bold",
          lineHeight: "initial",
        }}
      >
        {props.text}
      </span>
    </div>
  );
};
