import { JSX, PropsWithChildren } from "react";
import { PageContextProvider } from "../page/pageContext";
import { LookAndFeelContextProvider } from "./lookAndFeelContextProvider";
import { SiteContextProvider } from "./site-context";
import { Recommendations } from "../../recommendations-types";

type RecommendationsContextProps = {
  tenant: string;
  locale: string;
  page: string;
  mpvId: string | undefined;
  imageWidth: number | undefined;
  parentWorkId: string | undefined;
  recommendations: Recommendations;
};

export const RecommendationsContext = (
  props: PropsWithChildren<RecommendationsContextProps>,
): JSX.Element => {
  return (
    <SiteContextProvider tenant={props.tenant} locale={props.locale}>
      <LookAndFeelContextProvider imageWidth={props.imageWidth}>
        <PageContextProvider page={props.page} sourceMpvId={props.mpvId}>
          {props.children}
        </PageContextProvider>
      </LookAndFeelContextProvider>
    </SiteContextProvider>
  );
};
