import { Offer } from "../../recommendations-types";

export enum Events {
  ProductListViewed = "productListViewed",
  ProductAddedToCard = "productAddedToCart",
  ProductClicked = "productClicked",
  ErrorFetchingProductRecommendations = "errorFetchingProductRecommendations",
  DisabledRecommendations = "disabledRecommendations",
  QuantityChanged = "quantityChanged",
  ColorChanged = "colorChanged",
}

export type ProductListViewedEvent = {
  offerGroupTrackingId: string;
  offers: Offer[];
  set: number;
  discardedOffers?: boolean;
};

export type ProductAddedToCartEvent = {
  offer: Offer;
  workId: string | undefined;
  origin?: string;
  duration?: number;
};

export type ProductClickedEvent = {
  offer: Offer;
  clickedElement: string;
  origin?: string;
};

export type ErrorFetchingProductRecommendationsEvent = {
  errorMessage: string;
  error: unknown;
  contextData: Record<string, unknown>;
};

export type DisabledRecommendationsEvent = object;

export type QuantityChangedEvent = {
  offerGroupTrackingId: string;
  offerTrackingId: string;
  quantity: number;
};

export type ColorChangedEvent = {
  offerGroupTrackingId: string;
  offerTrackingId: string;
  productOptions: Record<string, string>;
  // TODO: remove undefined once we have a fallback image in case of errors
  imageUrl: string | undefined;
};
