import { PricePresentation, PricePresentationType } from "@vp/price-platform";
import { usePricingContext } from "@vp/react-pricing-context";
import { Fragment, JSX } from "react";
import { useSiteContext } from "../../recommendations-context";
import { OfferTilePriceProps } from "../tiles/common";

export const StartingAtPrice = (props: OfferTilePriceProps): JSX.Element => {
  const siteContext = useSiteContext();
  const pricingContext = usePricingContext();

  if (pricingContext === undefined) {
    return <Fragment></Fragment>;
  }

  const price = props.offer.prices.availablePrices.find(
    (p) => p.quantity === props.offer.quantity,
  );
  if (!price) {
    throw new Error(
      `unable to find price for ${props.offer.mpvId} for selected quantity ${
        props.offer.quantity
      }: ${JSON.stringify(props.offer.prices.availablePrices)}`,
    );
  }

  return (
    <>
      <PricePresentation
        presentationType={PricePresentationType.QtyStartingAtPrice}
        prices={{
          totalListPrice: price.listPrice,
          totalDiscountedPrice: price.discountedPrice,
        }}
        priceProperties={{
          minimumFractionDigits: props.offer.prices.fractionDigits,
          locale: siteContext.locale,
          currency: props.offer.prices.currency,
          vatInc: pricingContext.vatInclusive,
          quantity: props.offer.quantity,
        }}
      />
    </>
  );
};
