import React, { JSX, PropsWithChildren, useContext } from "react";
import { useScreenClass } from "@vp/swan";
import { DisplayConfiguration } from "../../recommendations-types";

export const ColumnCountContext = React.createContext<number | undefined>(
  undefined,
);

interface ColumnCountContextProviderProps {
  displayConfiguration: DisplayConfiguration;
}

// TODO: do we really need this?
export const ColumnCountContextProvider = (
  props: PropsWithChildren<ColumnCountContextProviderProps>,
): JSX.Element => {
  // const screenClass = useScreenClass();
  const screenClass = "lg" as ReturnType<typeof useScreenClass>;

  const getColumnCount = (): number => {
    if (screenClass === "xs") {
      return props.displayConfiguration.extraSmallColumnCount;
    }

    if (screenClass === "sm") {
      return props.displayConfiguration.smallColumnCount;
    }

    return props.displayConfiguration.columnCount;
  };

  return (
    <ColumnCountContext.Provider value={getColumnCount()}>
      {props.children}
    </ColumnCountContext.Provider>
  );
};

export const useColumnCount = (): number => {
  const context = useContext(ColumnCountContext);
  if (context === undefined) {
    throw new Error(
      "ColumnCountContext must be used with ColumnCountContextProvider",
    );
  }
  return context;
};
