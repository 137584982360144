import qs from "qs";
import { ProductOptions } from "../../../recommendations-types";

const baseUrl = "https://accessories.personalization.vpsvc.com/api/v1";

export type AresAssetsResponse = {
  imageUrl: string;
};

export async function fetchAsset(
  tenant: string,
  locale: string,
  mpvId: string,
  productOptions: ProductOptions,
  imageWidth: number | undefined,
): Promise<AresAssetsResponse> {
  const queryParams = {
    tenant: tenant,
    locale: locale,
    mpvId: mpvId,
    productOptions: productOptions,
    imageWidth: imageWidth,
    requestor: "recommendations-stack",
  };

  const url =
    `${baseUrl}/assets` +
    qs.stringify(queryParams, {
      addQueryPrefix: true,
      sort: (a: string, b: string) => a.localeCompare(b),
    });

  const response = await fetch(url);

  return response.ok ? await response.json() : Promise.reject(response);
}
