export function subscribe<T>(
  page: string,
  eventName: string,
  listener: (event: T) => void,
): EventListener {
  const listenerWrapper = (event: Event) => {
    if (!(event instanceof CustomEvent)) {
      return;
    }

    const customEvent = event as CustomEvent;

    if (page === customEvent.detail.page) {
      listener(customEvent.detail.data);
    }
  };
  document.addEventListener(eventName, listenerWrapper);
  return listenerWrapper;
}

export function unsubscribe(eventName: string, listener: EventListener) {
  document.removeEventListener(eventName, listener);
}

export function publish<T>(eventName: string, page: string, data: T) {
  document.dispatchEvent(newEvent(eventName, page, data));
}

function newEvent<T>(eventName: string, page: string, data: T): CustomEvent {
  return new CustomEvent(eventName, {
    detail: {
      page,
      data,
    },
  });
}
