import { SWAN_STYLE_KEY_MAP } from "@vp/swan";
import { RecommendationsSwanStyles as RecommendationsSwanStylesOfferTilesCommon } from "../recommendations-offer-tiles-common";

export {
  DefaultTileFactory,
  NewEditFlowTileFactory,
} from "./components/offer-tile";
export type { TileFactory } from "./components/offer-tile";
export * from "./components/offer-tiles/design-in-product-page";
export * from "./components/offer-tiles/edit-in-studio-add-to-cart";
export * from "./components/offer-tiles/edit-update-in-studio-add-to-cart";
export * from "./components/offer-tiles/must-edit-in-studio";
export * from "./components/offer-tiles/non-designable";
export * from "./components/offer-tiles/start-designing";
export { Placement } from "./components/placement";
export type { PlacementProps } from "./components/placement";

export const RecommendationsSwanStyles = [
  ...RecommendationsSwanStylesOfferTilesCommon,
  SWAN_STYLE_KEY_MAP.carousel,
  SWAN_STYLE_KEY_MAP.core,
  SWAN_STYLE_KEY_MAP.grid,
];
