import qs from "qs";
import { Product } from "./product";

function buildPreviewInstructionsUri(documentUri: string) {
  return (
    "https://uds.documents.cimpress.io/v3/instructions:preview" +
    qs.stringify(
      {
        documentUri: documentUri,
      },
      { addQueryPrefix: true },
    )
  );
}

const baseUrl = "https://lookup.previews.cimpress.io/api";

export function buildDefaultImageUrl(
  locale: string,
  product: Product,
  documentUri: string,
  width: number,
  purpose: string,
  requestor: string,
) {
  return (
    `${baseUrl}/v1/vistaprint-prod/${locale}/product/${product.key}/defaultViewImage` +
    qs.stringify(
      {
        productVersion: product.version,
        previewInstructionsUri: buildPreviewInstructionsUri(documentUri),
        purpose: purpose,
        optionSelections: JSON.stringify(product.options),
        width: width,
        requester: requestor,
      },
      { addQueryPrefix: true, sort: (a, b) => a.localeCompare(b) },
    )
  );
}

export function buildSceneOnlyImageUrl(
  locale: string,
  product: Product,
  width: number,
  purpose: string,
  requestor: string,
) {
  return (
    `${baseUrl}/v1/vistaprint-prod/${locale}/product/${product.key}/defaultViewImage` +
    qs.stringify(
      {
        productVersion: product.version,
        purpose: purpose,
        optionSelections: JSON.stringify(product.options),
        width: width,
        requester: requestor,
      },
      { addQueryPrefix: true, sort: (a, b) => a.localeCompare(b) },
    )
  );
}

export type View = {
  name: string;
  sceneType: string;
  _links: {
    image: {
      href: string;
      title: string;
    };
  };
};

export async function getBestViews(
  locale: string,
  product: Product,
  documentUri: string,
  width: number,
  purposes: string[],
  requestor: string,
): Promise<View[]> {
  const allViews = await getAllViews(
    locale,
    product,
    documentUri,
    width,
    purposes,
    requestor,
  );

  allViews.sort((v, w) => {
    if (
      sceneType(v.views) === "Photographic" &&
      sceneType(w.views) !== "Photographic"
    ) {
      return -1;
    }

    if (
      sceneType(w.views) === "Photographic" &&
      sceneType(v.views) !== "Photographic"
    ) {
      return 1;
    }

    return 0;
  });

  return allViews.length > 0 ? allViews[0].views : [];
}

function sceneType(views: View[]) {
  if (!views || views.length === 0) {
    return undefined;
  }

  return views[0].sceneType;
}

type ViewsResult = {
  purpose: string;
  views: View[];
};

async function getAllViews(
  locale: string,
  product: Product,
  documentUri: string,
  width: number,
  purposes: string[],
  requestor: string,
): Promise<ViewsResult[]> {
  return await Promise.all(
    purposes.map(async (purpose) => ({
      purpose: purpose,
      views: await getAllViewsForPurpose(
        locale,
        product,
        documentUri,
        width,
        purpose,
        requestor,
      ),
    })),
  );
}

async function getAllViewsForPurpose(
  locale: string,
  product: Product,
  documentUri: string,
  width: number,
  purpose: string,
  requestor: string,
): Promise<View[]> {
  const url =
    `${baseUrl}/v1/vistaprint-prod/${locale}/product/${product.key}/allViews` +
    qs.stringify(
      {
        productVersion: product.version,
        previewInstructionsUri: buildPreviewInstructionsUri(documentUri),
        purpose: purpose,
        optionSelections: JSON.stringify(product.options),
        width: width,
        requester: requestor,
      },
      { addQueryPrefix: true, sort: (a, b) => a.localeCompare(b) },
    );

  const response = await fetch(url);
  const responseAsObject = (await response.json()) as { views: View[] };
  return responseAsObject.views;
}
