import React, { JSX, useContext } from "react";

interface ISiteContext {
  tenant: string;
  locale: string;
}

export const SiteContext = React.createContext<ISiteContext | undefined>(
  undefined,
);

interface SiteContextProviderProps {
  tenant: string;
  locale: string;
  children: React.ReactNode;
}

export const SiteContextProvider = (
  props: SiteContextProviderProps,
): JSX.Element => {
  return (
    <SiteContext.Provider
      value={{
        tenant: props.tenant.toLowerCase(),
        locale: props.locale.toLowerCase(),
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

export const useSiteContext = (): ISiteContext => {
  const context = useContext(SiteContext);
  if (context === undefined) {
    throw new Error("useSiteContext must be used with SiteContextProvider");
  }
  return context;
};
