import { Link } from "@vp/swan";
import React, { PropsWithChildren } from "react";
import {
  AddToCartButton,
  ExactPrice,
  OfferTileAddedToCartOverlay,
  OfferTileCtaProps,
  OfferTilePriceProps,
  QuantitySelector,
  redirect,
  withCtaTile,
} from "../../../recommendations-offer-tiles-common";
import { LocalizedText, Texts } from "../../../recommendations-localization";
import { usePageContext } from "../../../recommendations-context";
import {
  Events,
  ProductClickedEvent,
  publish,
} from "../../../recommendations-common";
import { Offer } from "../../../recommendations-types";

function getDesignableUrl(
  configureUrl: string,
  productOptions: Record<string, string>,
  quantity: number,
  documentUrl: string,
  trackingId: string,
): string {
  const designableProductPageUrl = new URL(
    configureUrl,
    window.location.origin,
  );

  Object.entries(productOptions).forEach(([key, value]) => {
    designableProductPageUrl.searchParams.append(key, value);
  });

  designableProductPageUrl.searchParams.append("qty", quantity.toString());
  designableProductPageUrl.searchParams.append("documentUri", documentUrl);
  designableProductPageUrl.searchParams.append("recommendationsId", trackingId);

  return designableProductPageUrl.toString();
}

function DesignInProductPageOfferTileCta(props: OfferTileCtaProps) {
  return (
    <AddToCartButton
      offer={props.offer}
      onProductAddedToCart={props.onSuccess}
      onProductAddToCartError={props.onError}
      onProductAddToCartPermanentError={props.onPermanentError}
    />
  );
}

function DesignInProductPageLink(props: PropsWithChildren<OfferTileCtaProps>) {
  if (!props.offer.documentUrl || !props.offer.configureUrl) {
    throw new Error(
      "Cannot build a DesignInProductPageLink with neither an URL nor a document to configure the product",
    );
  }

  return (
    <GoToProductPageLink
      offer={props.offer}
      url={getDesignableUrl(
        props.offer.configureUrl,
        props.offer.productOptions,
        props.offer.quantity,
        props.offer.documentUrl,
        props.offer.trackingId,
      )}
      skin="unstyled"
    >
      {props.children}
    </GoToProductPageLink>
  );
}

export const DesignInProductPageOfferTile = withCtaTile(
  "DesignInProductPageOfferTile",
  DesignInProductPageOfferTileCta,
  (props: OfferTileCtaProps) => (
    <DesignInProductPageLink {...props}>
      <LocalizedText text={Texts.Edit} />
    </DesignInProductPageLink>
  ),
  DesignInProductPageLink,
  OfferTileAddedToCartOverlay,
  null,
  (props: OfferTilePriceProps) => <ExactPrice offer={props.offer} />,
  (props: OfferTilePriceProps) => <QuantitySelector offer={props.offer} />,
);

type StartDesignButtonProps = {
  offer: Offer;
  url: string;
  skin: "cta" | "unstyled";
};

function GoToProductPageLink(
  props: React.PropsWithChildren<StartDesignButtonProps>,
) {
  const pageContext = usePageContext();

  return (
    <Link
      to={props.url}
      skin="standard"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        publish<ProductClickedEvent>(Events.ProductClicked, pageContext.page, {
          offer: props.offer,
          clickedElement: "Tile",
        });
        redirect(props.url);
      }}
    >
      {props.children}
    </Link>
  );
}
