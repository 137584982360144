import { Button } from "@vp/swan";
import React, { JSX } from "react";
import { useSiteContext } from "../../recommendations-context";
import { localizeText, Texts } from "../../recommendations-localization";

export const EditButton = React.forwardRef<HTMLElement, unknown>(
  function EditButton(_, ref): JSX.Element {
    const siteContext = useSiteContext();

    return (
      <Button
        skin="primary"
        width="full-width"
        ref={ref}
        style={{ whiteSpace: "unset" }}
      >
        {localizeText(Texts.CrossSellStudioCta, siteContext.locale)}
      </Button>
    );
  },
);
