import {
  Box,
  Dropdown,
  DropdownOption,
  FormField,
  FormLabel,
  StandardForm,
} from "@vp/swan";
import React, { JSX } from "react";
import {
  Events,
  publish,
  QuantityChangedEvent,
} from "../../recommendations-common";
import {
  useLookAndFeelContext,
  usePageContext,
  useSiteContext,
} from "../../recommendations-context";
import { localizeText, Texts } from "../../recommendations-localization";
import { Offer } from "../../recommendations-types";

export type QuantitySelectorProps = {
  offer: Offer;
};

export function QuantitySelector(props: QuantitySelectorProps): JSX.Element {
  const siteContext = useSiteContext();
  const { isLargeOrMediumScreen } = useLookAndFeelContext();

  return (
    <StandardForm>
      <Box mt={isLargeOrMediumScreen ? 4 : 3} mb={4}>
        <FormField>
          <Box mb={2}>
            <FormLabel>
              {localizeText(Texts.Quantity, siteContext.locale)}
            </FormLabel>
          </Box>
          <QuantityDropdown offer={props.offer} />
        </FormField>
      </Box>
    </StandardForm>
  );
}

export type QuantityDropdownProps = {
  offer: Offer;
};

function QuantityDropdown(props: QuantityDropdownProps): JSX.Element {
  const pageContext = usePageContext();

  const quantities = props.offer.prices.availablePrices.map((p) => p.quantity);

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newQuantity = parseInt(event.target.value);
    publish<QuantityChangedEvent>(Events.QuantityChanged, pageContext.page, {
      offerGroupTrackingId: props.offer.offerGroupTrackingId,
      offerTrackingId: props.offer.trackingId,
      quantity: newQuantity,
    });
  };

  return (
    <Dropdown
      disabled={quantities.length === 1}
      fullWidth
      onChange={handleQuantityChange}
      value={props.offer.quantity.toString()}
    >
      {quantities.map((qty: number, key: number) => (
        <DropdownOption key={key} value={qty.toString()}>
          {qty}
        </DropdownOption>
      ))}
    </Dropdown>
  );
}
