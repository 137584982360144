import qs from "qs";
import { Product } from "./product";

const baseUrl = "https://design-specifications.design.vpsvc.com";

export async function getDesignDocument(
  product: Product,
  templateToken: string,
  culture: string,
  requester: string,
) {
  const response = await fetch(
    buildDesignDocumentUrl(product, templateToken, culture, requester),
  );
  return response.ok ? await response.json() : Promise.reject(response);
}

export function buildDesignDocumentUrl(
  product: Product,
  templateToken: string,
  culture: string,
  requester: string,
) {
  return (
    `${baseUrl}/v2/designDocuments/products/${product.key}/${product.version}` +
    qs.stringify(
      {
        optionSelections: product.options,
        templateToken: templateToken,
        culture: culture,
        requester: requester,
      },
      { addQueryPrefix: true },
    )
  );
}
