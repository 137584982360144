import { PropsWithChildren } from "react";
import {
  OfferTileCtaProps,
  AddToCartButton,
  EditInStudioLink,
  withCtaTile,
  OfferTileAddedToCartOverlay,
  OfferTilePriceProps,
  ExactPrice,
  QuantitySelectorProps,
  QuantitySelector,
} from "../../../recommendations-offer-tiles-common";

function EditInStudioOfferTileCta(props: OfferTileCtaProps) {
  return (
    <AddToCartButton
      offer={props.offer}
      onProductAddedToCart={props.onSuccess}
      onProductAddToCartError={props.onError}
      onProductAddToCartPermanentError={props.onPermanentError}
    />
  );
}

function EditInStudioOfferTileLink(props: OfferTileCtaProps) {
  return (
    <EditInStudioLink offer={props.offer} skin="standard" covering={false} />
  );
}

export const EditInStudioAddToCartOfferTile = withCtaTile(
  "EditInStudioAddToCartOfferTile",
  EditInStudioOfferTileCta,
  EditInStudioOfferTileLink,
  (props: PropsWithChildren<OfferTileCtaProps>) => <>{props.children}</>,
  OfferTileAddedToCartOverlay,
  null,
  (props: OfferTilePriceProps) => <ExactPrice {...props} />,
  (props: QuantitySelectorProps) => <QuantitySelector {...props} />,
);
