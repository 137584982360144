import { QuantityPrice } from "@vp/poe-client";
import { PricePresentation, PricePresentationType } from "@vp/price-platform";
import { usePricingContext } from "@vp/react-pricing-context";
import { JSX } from "react";
import { useSiteContext } from "../../recommendations-context";
import { OfferTilePriceProps } from "../tiles/common";

export const ExactPrice = (props: OfferTilePriceProps): JSX.Element | null => {
  const siteContext = useSiteContext();
  const priceContext = usePricingContext();

  if (!priceContext) {
    return null;
  }

  const { listPrice, discountedPrice } = priceForQuantity(
    props.offer.prices.availablePrices,
    props.offer.quantity,
  );

  return (
    <>
      <PricePresentation
        presentationType={PricePresentationType.RawPrice}
        prices={{
          totalListPrice: listPrice,
          totalDiscountedPrice: discountedPrice,
        }}
        priceProperties={{
          minimumFractionDigits: props.offer.prices.fractionDigits,
          locale: siteContext.locale,
          currency: props.offer.prices.currency,
          vatInc: priceContext.vatInclusive,
          quantity: props.offer.quantity,
        }}
      />
    </>
  );
};

const priceForQuantity = (
  prices: QuantityPrice[],
  quantity: number,
): QuantityPrice => {
  const price = prices.find((p) => p.quantity === quantity);
  if (!price) {
    throw new Error(
      `price not found for quantity: ${quantity} in quantities ${JSON.stringify(
        prices,
      )}`,
    );
  }
  return price;
};
