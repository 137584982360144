import { Button, Link, VisuallyHidden } from "@vp/swan";
import React, { JSX, ReactNode, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import {
  Events,
  ProductClickedEvent,
  publish,
} from "../../recommendations-common";
import { usePageContext, useSiteContext } from "../../recommendations-context";
import { LocalizedText, Texts } from "../../recommendations-localization";
import { Offer } from "../../recommendations-types";
import { redirect } from "../navigation/redirect";
import { buildStudioUrl } from "../studio/url";

interface EditInStudioLinkProps {
  offer: Offer;
  skin?: "cta" | "primary" | "standard";
  covering?: boolean;
  localizedText?: string;
}

export const EditInStudioLink = (
  props: React.PropsWithChildren<EditInStudioLinkProps>,
): JSX.Element => {
  const [studioUrl, setStudioUrl] = useState("");
  const siteContext = useSiteContext();
  const pageContext = usePageContext();
  const { showBoundary } = useErrorBoundary();

  const offer = props.offer;
  const covering = props.covering !== undefined ? props.covering : true;

  useEffect(() => {
    const execute = async () => {
      if (!offer.documentUrl) {
        showBoundary(
          new Error(
            "cannot edit in studio if offer does not contain a document url",
          ),
        );
        return;
      }

      const studioUrl = await buildStudioUrl(
        siteContext.locale,
        offer.productKey,
        offer.productVersion,
        offer.productOptions,
        offer.mpvId,
        offer.documentUrl,
        offer.quantity,
        offer.offerGroupTrackingId,
      );

      setStudioUrl(studioUrl);
    };

    execute();
  }, [
    siteContext.locale,
    offer.productKey,
    offer.productVersion,
    offer.productOptions,
    offer.mpvId,
    offer.documentUrl,
    offer.quantity,
    offer.offerGroupTrackingId,
    showBoundary,
  ]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      publish<ProductClickedEvent>(Events.ProductClicked, pageContext.page, {
        offer: offer,
        clickedElement: covering ? "Tile" : "Edit",
      });
      redirect(studioUrl);
    } catch (error) {
      showBoundary(error);
    }
  };

  function createLink(children: ReactNode, skin?: "cta" | "standard") {
    return (
      <Link
        skin={skin || "unstyled"}
        href={studioUrl}
        onClick={handleClick}
        covering={covering}
      >
        {children}
        <VisuallyHidden>{offer.title}</VisuallyHidden>
      </Link>
    );
  }

  function createButton(children: ReactNode) {
    return (
      <Button
        skin="primary"
        width="full-width"
        render={(props) => (
          <Link
            href={studioUrl}
            // eslint-disable-next-line react/prop-types
            className={props.className}
            onClick={handleClick}
          >
            {props.children}
            <VisuallyHidden>{offer.title}</VisuallyHidden>
          </Link>
        )}
      >
        {children}
      </Button>
    );
  }

  if (!studioUrl) {
    return <></>;
  }

  return props.skin === "primary"
    ? createButton(<LocalizedText text={props.localizedText || Texts.Edit} />)
    : createLink(
        props.children || (
          <LocalizedText text={props.localizedText || Texts.Edit} />
        ),
        props.skin,
      );
};
