import { PropsWithChildren } from "react";
import { Texts } from "../../../recommendations-localization";
import {
  AddToCartButton,
  EditInStudioLink,
  ExactPrice,
  OfferTileAddedToCartOverlay,
  OfferTileCtaProps,
  OfferTilePriceProps,
  QuantitySelector,
  QuantitySelectorProps,
  withCtaTile,
} from "../../../recommendations-offer-tiles-common";
import { Offer } from "../../../recommendations-types";

function shouldShowUpdateAndAddToCart(offer: Offer) {
  return offer.usesPlaceholderCopy || offer.hasQuantitySelectionPage;
}

function EditInStudioOfferTileCta(props: OfferTileCtaProps) {
  return shouldShowUpdateAndAddToCart(props.offer) ? (
    <EditInStudioLink
      offer={props.offer}
      skin="primary"
      covering={false}
      localizedText={Texts.CrossSellStudioCta}
    />
  ) : (
    <AddToCartButton
      offer={props.offer}
      onProductAddedToCart={props.onSuccess}
      onProductAddToCartError={props.onError}
      onProductAddToCartPermanentError={props.onPermanentError}
    />
  );
}

function EditInStudioOfferTileLink(props: OfferTileCtaProps) {
  return shouldShowUpdateAndAddToCart(props.offer) ? null : (
    <EditInStudioLink offer={props.offer} skin="standard" covering={false} />
  );
}

function EditInStudioOfferTileImage(
  props: PropsWithChildren<OfferTileCtaProps>,
) {
  return (
    <EditInStudioLink offer={props.offer} skin="standard" covering={false}>
      {props.children}
    </EditInStudioLink>
  );
}

export const EditUpdateInStudioAddToCartOfferTile = withCtaTile(
  "EditUpdateInStudioAddToCartOfferTile",
  EditInStudioOfferTileCta,
  EditInStudioOfferTileLink,
  EditInStudioOfferTileImage,
  OfferTileAddedToCartOverlay,
  null,
  (props: OfferTilePriceProps) => <ExactPrice {...props} />,
  (props: QuantitySelectorProps) => <QuantitySelector {...props} />,
);
