import { Link } from "@vp/swan";
import React, { PropsWithChildren } from "react";
import {
  Events,
  ProductClickedEvent,
  publish,
} from "../../../recommendations-common";
import { usePageContext } from "../../../recommendations-context";
import {
  OfferTileLinkProps,
  OfferTilePriceProps,
  redirect,
  StartingAtPrice,
  withLinkTile,
} from "../../../recommendations-offer-tiles-common";
import { Offer } from "../../../recommendations-types";

function StartDesigningOfferTileLink(
  props: PropsWithChildren<OfferTileLinkProps>,
) {
  if (!props.offer.configureUrl) {
    throw new Error(
      "Cannot build a StartDesigningOfferTile without a URL to configure the product",
    );
  }
  return (
    <StartDesigningLink
      offer={props.offer}
      url={props.offer.configureUrl}
      skin="unstyled"
    >
      {props.children}
    </StartDesigningLink>
  );
}

type StartDesignButtonProps = {
  offer: Offer;
  url: string;
  skin: "cta" | "unstyled";
};

function StartDesigningLink(
  props: React.PropsWithChildren<StartDesignButtonProps>,
) {
  const pageContext = usePageContext();

  return (
    <Link
      to={props.url}
      skin={props.skin}
      width="full-width"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        publish<ProductClickedEvent>(Events.ProductClicked, pageContext.page, {
          offer: props.offer,
          clickedElement: "Tile",
        });
        redirect(props.url);
      }}
      covering
    >
      {props.children}
    </Link>
  );
}

export const StartDesigningOfferTile = withLinkTile(
  "StartDesigningOfferTile",
  {
    Link: StartDesigningOfferTileLink,
    Location: "name",
  },
  (props: OfferTilePriceProps) => <StartingAtPrice {...props} />,
);
