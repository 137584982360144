import { PricingContextProvider } from "@vp/react-pricing-context";
import { BoundedContent } from "@vp/swan";
import {
  IdentityProvider,
  IdentityProviderProps,
  useLogger,
  useSwanStyleKeys,
} from "@vp/ubik-context";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AuthContextProvider } from "./common/auth/context";
import { buildDesignDocumentUrl } from "./common/dss";
import { buildDefaultImageUrl } from "./common/lookup";
import { RecommendationsContext } from "./common/recstk/recommendations-context";
import {
  DefaultTileFactory,
  Placement,
  RecommendationsSwanStyles,
} from "./common/recstk/recommendations-renderer";
import { Recommendations } from "./common/recstk/recommendations-types";

type VPAuthConfig = IdentityProviderProps["auth"];

export interface Props {
  tenant: string;
  locale: string;
  placement: string;
  recommendations: Recommendations;
  auth: VPAuthConfig;
  experience: string;
}

export const Fragment = ({ auth, ...props }: Props) => {
  useSwanStyleKeys(RecommendationsSwanStyles);
  const logger = useLogger();

  return (
    <ErrorBoundary
      fallback={<></>}
      onError={(error: Error) => {
        logger.error("Error captured at boundary", error);
      }}
    >
      <IdentityProvider auth={auth}>
        <AuthContextProvider>
          <PricingContextProvider>
            <BoundedContent>
              <FragmentContent {...props} />
            </BoundedContent>
          </PricingContextProvider>
        </AuthContextProvider>
      </IdentityProvider>
    </ErrorBoundary>
  );
};

type FragmentContentProps = Omit<Props, "auth">;

function FragmentContent(props: FragmentContentProps) {
  if (props.experience === "incremental") {
    return <IncrementalRecommendations {...props} />;
  }

  return <NormalRecommendationsContext {...props} />;
}

type NormalRecommendationsProps = Omit<FragmentContentProps, "experience">;

function NormalRecommendationsContext(props: NormalRecommendationsProps) {
  return (
    <RecommendationsContext
      tenant={props.tenant}
      locale={props.locale}
      page={props.placement}
      mpvId={undefined}
      imageWidth={300}
      parentWorkId=""
      recommendations={props.recommendations}
    >
      <Placement
        recommendations={props.recommendations}
        tileFactory={new DefaultTileFactory()}
      />
    </RecommendationsContext>
  );
}

type IncrementalRecommendationsProps = Omit<FragmentContentProps, "experience">;

function IncrementalRecommendations(props: IncrementalRecommendationsProps) {
  const [recommendations, setRecommendations] = useState(props.recommendations);

  useEffect(() => {
    setTimeout(() => {
      setRecommendations((prev) => addMatchingToRecommendations(prev));
    }, 2000);
  }, []);

  return (
    <>
      <RecommendationsContext
        tenant={props.tenant}
        locale={props.locale}
        page={props.placement}
        mpvId={undefined}
        imageWidth={300}
        parentWorkId=""
        recommendations={recommendations}
      >
        <Placement
          recommendations={recommendations}
          tileFactory={new DefaultTileFactory()}
        />
      </RecommendationsContext>
    </>
  );
}

function addMatchingToRecommendations(
  currRecommendations: Recommendations,
): Recommendations {
  return {
    ...currRecommendations,
    offersGroups: currRecommendations.offersGroups.map((og) => ({
      ...og,
      offers: og.offers.map((o) => {
        let imageUrl = o.imageUrl;

        try {
          const documentUrl = buildDesignDocumentUrl(
            {
              key: o.productKey,
              version: o.productVersion,
              options: o.productOptions,
              mpvId: o.mpvId,
            },
            getTemplateToken(o.mpvId),
            "en-us",
            "vj",
          );
          imageUrl = buildDefaultImageUrl(
            "en-us",
            {
              key: o.productKey,
              version: o.productVersion,
              options: o.productOptions,
              mpvId: o.mpvId,
            },
            documentUrl,
            300,
            "merchandising.cross-sell.tile",
            "vj",
          );
        } catch {
          console.error("Error getting document URL");
        }

        return {
          ...o,
          imageUrl: imageUrl,
        };
      }),
    })),
  };
}

function getTemplateToken(mpvId: string) {
  switch (mpvId) {
    case "caBasicTShirts":
      return "c3831354..767d635e-c9d1-480c-be31-bd7c1f919eda";
    case "canvasPrints":
    case "carDoorMagnets":
    case "customSheetStickers":
      return "c4314068..911114da-235d-4a05-ad23-f82b8949f8a1";
    case "deluxeCottonTote":
    case "flyers":
    case "giftCertificate":
      return "sd5e0456c-a258-43bd-bf1c-6795a2396665:v3..47cc21e6-a370-49af-97b7-bc2eb63ea1c0";
    case "magneticCalendars":
    case "mousePads":
      return "sf7b838ad-86e5-4aa4-8804-f503504e77cd:v2..22988702-4e85-477f-9c53-434688cff0e6";
    case "notebooks":
    case "paperBags":
    case "personalizedMugs":
    case "premiumBallpointPens":
      return "s968279a7-18be-4eae-8b7e-bba96eaf8473:v2..14e3edc2-d041-4e4d-88c6-ab676fc6d83c";
    case "standardBusinessCards":
      return "c6559755..f9c58b2a-e04f-4251-a983-6eb421ba478d";
    case "standardEmbroideredHats":
    case "yardSigns":
      return "c2470669..001db55d-35f9-40d0-bac4-01f7545ce367";
  }

  throw new Error("Unknown mpvId");
}
