import {
  ColorSwatch,
  ColorSwatches,
  SelectedValue,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
} from "@vp/swan";
import { JSX, useMemo } from "react";
import { fetchAsset } from "../clients/ares/client";
import { getLocalizedValue } from "../product/options";
import { Offer } from "../../recommendations-types";
import {
  publish,
  ColorChangedEvent,
  Events,
} from "../../recommendations-common";
import { useSiteContext, usePageContext } from "../../recommendations-context";
import { localizeText, Texts } from "../../recommendations-localization";

type ColorOptionsProps = {
  offer: Offer;
  imageWidth: number | undefined;
};

export function ColorOptions(props: ColorOptionsProps): JSX.Element {
  const offer = props.offer;

  const colorOptions = useMemo(
    () =>
      offer.selectableOptions.find(
        (x) => x.name === "Color" && x.values.length > 1,
      ),
    [JSON.stringify(offer.selectableOptions)],
  );

  const siteContext = useSiteContext();
  const pageContext = usePageContext();

  const colorChanged = async (colorValue: SelectedValue) => {
    if (!colorValue) {
      return;
    }

    let imageUrl: string | undefined = undefined;

    try {
      const asset = await fetchAsset(
        siteContext.tenant,
        siteContext.locale,
        offer.mpvId,
        { ...offer.productOptions, Color: colorValue },
        props.imageWidth,
      );
      if (asset) {
        imageUrl = asset.imageUrl;
      }
      // TODO: use fallback? Right now we show the same image.
      // Once we have assets for for all regions we can use an error icon.
    } catch (error) {}

    publish<ColorChangedEvent>(Events.ColorChanged, pageContext.page, {
      offerGroupTrackingId: offer.offerGroupTrackingId,
      offerTrackingId: offer.trackingId,
      productOptions: { ...offer.productOptions, Color: colorValue },
      imageUrl: imageUrl,
    });
  };

  return colorOptions ? (
    <SelectionSet
      defaultSelectedValue={offer.productOptions["Color"]}
      aria-label={localizeText(Texts.ColorSelection, siteContext.locale)}
      onSelectedValueChange={async (newColor: SelectedValue) =>
        await colorChanged(newColor)
      }
    >
      <ColorSwatches marginTop={4} marginBottom={4}>
        {colorOptions?.values.map((value: string) => {
          const color = getLocalizedValue(
            "Color",
            value,
            offer.localizedOptions,
          );
          return (
            <SelectionSetInput name={value} value={value} key={value}>
              <SelectionSetLabel>
                <ColorSwatch
                  primaryColor={value}
                  title={color}
                  accessibleText={color}
                  aria-label={color}
                />
              </SelectionSetLabel>
            </SelectionSetInput>
          );
        })}
      </ColorSwatches>
    </SelectionSet>
  ) : (
    <></>
  );
}
