import { JSX } from "react";
import { useSiteContext } from "../../recommendations-context";
import { localizeText, Texts } from "../../recommendations-localization";
import { OfferTileImageOverlay } from "../image/image-overlay";

export const OfferTileAddedToCartOverlay = (): JSX.Element => {
  const siteContext = useSiteContext();
  return (
    <OfferTileImageOverlay
      text={localizeText(Texts.AddedToCart, siteContext.locale)}
    />
  );
};
