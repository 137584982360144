import { Category } from "./category";

export type Recommendations = {
  offersGroups: OfferGroup[];
  disabled: boolean;
};

export type OfferGroup = {
  trackingId: string;
  actions: OfferActions;
  displayConfiguration: DisplayConfiguration;
  offers: Offer[];
};

export enum Layout {
  Grid = "grid",
  Carousel = "carousel",
}

export type MinOffersToDisplay = {
  default: number;
  small: number;
  extraSmall: number;
};

export type DisplayConfiguration = {
  title: string;
  columnCount: number;
  smallColumnCount: number;
  extraSmallColumnCount: number;
  layout: Layout;
  minOffersToDisplay?: MinOffersToDisplay;
};

export enum OfferAction {
  AddToCart = "AddToCart",
  EditInStudio = "EditInStudio",
  EditUpdateInStudioAddToCart = "EditUpdateInStudioAddToCart",
  EditInPage = "EditInPage",
  // TODO: why not using "Configure" for the next two actions? Is it because of tracking?
  GoToGallery = "GoToGallery",
  GoToProductPage = "GoToProductPage",
}

export type OfferActions = { [key in OfferType]: OfferAction | undefined };

export type ProductOptions = Record<string, string>;

export type Offer = {
  trackingId: string;
  offerGroupTrackingId: string;
  title: string;
  description: string;
  offerType: OfferType;
  mpvId: string;
  productKey: string;
  productVersion: number;
  productOptions: ProductOptions;
  selectableOptions: SelectableOption[];
  localizedOptions: { [key: string]: LocalizedOption };
  prices: Prices;
  quantity: number;
  categories: Category[];
  hasQuantitySelectionPage: boolean;
  imageUrl: string;
  documentUrl?: string;
  configureUrl?: string;
  sourceDocumentUrl?: string;
  isEditableInCrossSellStudio?: boolean;
  // TODO: we might not need this at all (simply move the logic from ARES to here).
  isEditableInNanoStudio?: boolean;
  isAccessory: boolean;
  usesPlaceholderCopy: boolean | undefined;
};

export enum OfferType {
  Image = "Image",
  DocumentMatching = "DocumentMatching",
  NonDesignable = "NonDesignable",
  SmartAssets = "SmartAssets",
}

export interface SelectableOption {
  name: string;
  values: string[];
}

export interface LocalizedOption {
  name: string;
  values: { [key: string]: string };
}

export type Prices = {
  currency: string;
  fractionDigits: number;
  availablePrices: QuantityPrice[];
};

export type QuantityPrice = {
  quantity: number;
  listPrice: Price;
  discountedPrice: Price;
};

export type Price = {
  taxed: number;
  untaxed: number;
};

export const totalOfferCount = (recommendations: Recommendations): number =>
  recommendations.offersGroups.reduce(
    (acc, group) => acc + group.offers.length,
    0,
  );

export const priceValue = (price: Price, vatIncluded: boolean): number =>
  vatIncluded ? price.taxed : price.untaxed;
