import { Link, Button } from "@vp/swan";
import React, { JSX } from "react";
import { LocalizedText, Texts } from "../../recommendations-localization";

export const EditLink = React.forwardRef<HTMLElement, unknown>(
  function EditLink(_, ref): JSX.Element {
    return (
      <Link
        render={(p) => (
          <Button skin="link" ref={ref} className={p.className}>
            {p.children}
          </Button>
        )}
      >
        <LocalizedText text={Texts.Edit} />
      </Link>
    );
  },
);
