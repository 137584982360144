import {
  BasicResponsiveImage,
  Box,
  FlexBox,
  Span,
  StandardTile,
  StandardTileContents,
  StandardTileDescription,
  StandardTileFooter,
  StandardTileImage,
  StandardTileName,
  StandardTilePrice,
  StandardTileSeparatelyClickableContents,
  Typography,
} from "@vp/swan";
import React, { useEffect, useState } from "react";
import { LocalizedText } from "../../recommendations-localization";
import { Offer } from "../../recommendations-types";
import { useFocus } from "../hooks/useFocus";
import { QuantitySelectorProps } from "../quantity/quantitySelector";

export type OfferTileCtaProps = {
  offer: Offer;
  onSuccess: () => void;
  onClosed?: () => void;
  onError?: (errorMessage: string) => void;
  onPermanentError?: () => void;
};

export type OfferTileLinkProps = {
  offer: Offer;
};

export type OfferTileColorsProps = {
  offer: Offer;
};

export type OfferTilePriceProps = {
  offer: Offer;
};

export type OfferTileProps = {
  offer: Offer;
};

export type TileLink = {
  Link: React.FC<React.PropsWithChildren<OfferTileLinkProps>>;
  Location: "name" | "bottom";
};

export function withLinkTile(
  displayName: string,
  Link: TileLink,
  Price: React.FC<OfferTilePriceProps>,
): React.FC<OfferTileProps> {
  return withOfferTile(
    displayName,
    null,
    null,
    null,
    null,
    Link,
    Price,
    null,
    null,
  );
}

export function withCtaTile(
  displayName: string,
  Cta: React.FC<OfferTileCtaProps>,
  SecondaryCta: React.FC<OfferTileCtaProps> | null,
  ImageCta: React.FC<React.PropsWithChildren<OfferTileCtaProps>> | null,
  ImageOverlay: React.FC<unknown> | null,
  Colors: React.FC<OfferTileColorsProps> | null,
  Price: React.FC<OfferTilePriceProps>,
  QuantitySelector: React.FC<QuantitySelectorProps> | null,
): React.FC<OfferTileProps> {
  return withOfferTile(
    displayName,
    Cta,
    SecondaryCta,
    ImageCta,
    ImageOverlay,
    null,
    Price,
    Colors,
    QuantitySelector,
  );
}

function withOfferTile(
  displayName: string,
  Cta: React.FC<OfferTileCtaProps> | null,
  SecondaryCta: React.FC<OfferTileCtaProps> | null,
  ImageCta: React.FC<React.PropsWithChildren<OfferTileCtaProps>> | null,
  ImageOverlay: React.FC<unknown> | null,
  Link: TileLink | null,
  Price: React.FC<OfferTilePriceProps>,
  Colors: React.FC<OfferTileColorsProps> | null,
  QuantitySelector: React.FC<QuantitySelectorProps> | null,
): React.FC<OfferTileProps> {
  const Component: React.FC<OfferTileProps> = (props: OfferTileProps) => {
    const [ctaSuccess, setCtaSuccess] = useState(false);
    const [focusRef, focus] = useFocus();

    const tileProps = {
      offer: props.offer,
      Cta,
      SecondaryCta,
      ImageCta,
      ImageOverlay,
      Link,
      Colors,
      Price,
      QuantitySelector,
      ctaSuccess,
      setCtaSuccess,
      onClosed: () => {
        focus();
      },
    };

    useEffect(() => {
      if (ctaSuccess) {
        focus();
      }
    }, [ctaSuccess, focus]);

    return <Tile {...tileProps} ref={focusRef} />;
  };

  Component.displayName = displayName;

  return Component;
}

type TileProps = TileImageProps & TileContentsProps & TileFooterProps;

const Tile = React.forwardRef<HTMLElement, TileProps>(function Tile(
  props: TileProps,
  ref,
) {
  return (
    <StandardTile>
      <TileImage {...props} />
      <TileContents {...props} ref={ref} />
      <TileFooter {...props} />
    </StandardTile>
  );
});

type TileImageProps = {
  offer: Offer;
  ImageCta: React.FC<React.PropsWithChildren<OfferTileCtaProps>> | null;
  ImageOverlay: React.FC<unknown> | null;
  ctaSuccess: boolean;
  setCtaSuccess: (success: boolean) => void;
  onClosed: () => void;
};

function TileImage(props: TileImageProps) {
  const ImageCta = props.ctaSuccess
    ? ChildrenPassthrough()
    : ChildrenFallback(props.ImageCta);
  const ImageOverlay = EmptyFallback(props.ImageOverlay);

  const isFinal = props.offer.imageUrl.includes("previewInstructionsUri");

  const baseImageUrl = new URL(props.offer.imageUrl);
  baseImageUrl.searchParams.delete("previewInstructionsUri");

  return (
    <>
      <ImageCta
        offer={props.offer}
        onSuccess={() => {
          props.setCtaSuccess(true);
        }}
        onClosed={props.onClosed}
      >
        <StandardTileImage>
          <BasicResponsiveImage
            aspectRatio={1}
            src={baseImageUrl.toString()}
            alt=""
          />

          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "100%",
              transition: "opacity 1s ease-in-out",
              opacity: isFinal ? 1 : 0,
            }}
          >
            <BasicResponsiveImage
              aspectRatio={1}
              src={props.offer.imageUrl}
              alt=""
            />
          </div>
        </StandardTileImage>
        {!props.ctaSuccess || <ImageOverlay />}
      </ImageCta>
    </>
  );
}

type TileContentsProps = {
  offer: Offer;
  SecondaryCta: React.FC<OfferTileCtaProps> | null;
  Link: TileLink | null;
  Price: React.FC<OfferTilePriceProps>;
  Colors: React.FC<OfferTileColorsProps> | null;
  ctaSuccess: boolean;
  setCtaSuccess: (success: boolean) => void;
};

const TileContents = React.forwardRef<HTMLElement, TileContentsProps>(
  (props: TileContentsProps, ref) => {
    const { SecondaryCta, Link, Price, Colors } = props;

    const NameLink =
      Link && Link.Location == "name" ? Link.Link : ChildrenPassthrough();

    const BottomLink =
      Link && Link.Location == "bottom" ? Link.Link : () => <></>;

    return (
      <StandardTileContents>
        <FlexBox justifyContent="space-between">
          <Span tabIndex={-1} ref={ref}>
            <NameLink offer={props.offer}>
              <StandardTileName component="h4">
                {props.offer.title}
              </StandardTileName>
            </NameLink>
          </Span>

          {!props.ctaSuccess && SecondaryCta && (
            <SecondaryCta
              offer={props.offer}
              onSuccess={() => {
                props.setCtaSuccess(true);
              }}
            />
          )}
        </FlexBox>
        <StandardTileDescription>
          {props.offer.description}
        </StandardTileDescription>
        <StandardTilePrice>
          <Price offer={props.offer} />
        </StandardTilePrice>
        <TileColors offer={props.offer} Colors={Colors} usesLink={!!Link} />
        <BottomLink offer={props.offer} />
      </StandardTileContents>
    );
  },
);

TileContents.displayName = "TileContents";

type TileColors = {
  offer: Offer;
  Colors: React.FC<OfferTileColorsProps> | null;
  usesLink: boolean;
};

function TileColors(props: TileColors) {
  const { Colors } = props;

  if (!Colors) {
    return <></>;
  }

  return props.usesLink ? (
    <StandardTileSeparatelyClickableContents>
      <Colors offer={props.offer} />
    </StandardTileSeparatelyClickableContents>
  ) : (
    <Colors offer={props.offer} />
  );
}

type TileFooterProps = {
  offer: Offer;
  Cta: React.FC<OfferTileCtaProps> | null;
  QuantitySelector: React.FC<QuantitySelectorProps> | null;
  ctaSuccess: boolean;
  setCtaSuccess: (success: boolean) => void;
};

function TileFooter(props: TileFooterProps) {
  const { QuantitySelector, Cta } = props;
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [permanentError, setPermanentError] = useState(false);

  if (props.ctaSuccess || !QuantitySelector || !Cta) {
    return <></>;
  }

  return (
    <StandardTileFooter>
      {errorOccurred && (
        <Box mb={4}>
          <Typography textColor="error">
            <LocalizedText text={errorMessage} />
          </Typography>
        </Box>
      )}
      {!permanentError && (
        <>
          <QuantitySelector offer={props.offer} />
          <Cta
            offer={props.offer}
            onSuccess={() => {
              setErrorOccurred(false);
              props.setCtaSuccess(true);
            }}
            onError={(errMsg: string) => {
              setErrorOccurred(true);
              setErrorMessage(errMsg);
            }}
            onPermanentError={() => {
              setPermanentError(true);
            }}
          />
        </>
      )}
    </StandardTileFooter>
  );
}

function EmptyFallback<P>(component: React.FC<P> | null) {
  return component ? component : () => <></>;
}

function ChildrenFallback<P>(component: React.FC<P> | null) {
  return component ? component : ChildrenPassthrough();
}

function ChildrenPassthrough() {
  return (props: React.PropsWithChildren<unknown>) => <>{props.children}</>;
}
