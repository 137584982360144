import { getStudioUrl } from "../clients/url/client";

export const buildStudioUrl = async (
  locale: string,
  productKey: string,
  productVersion: number,
  options: Record<string, string>,
  mpvId: string,
  documentUrl: string,
  quantity: number,
  trackingId: string,
): Promise<string> => {
  const url = await getStudioUrl(locale, productKey, productVersion, options);

  const params = new URLSearchParams();
  params.append("locale", locale);
  params.append("key", productKey);
  params.append("productVersion", productVersion.toString());
  params.append("selectedOptions", JSON.stringify(options));
  params.append("mpvId", mpvId);
  params.append("qty", quantity.toString());
  params.append("documentUrl", documentUrl);
  params.append("recommendationsId", trackingId);
  params.sort();

  const studioUrl = `${url}?${params.toString()}`;
  if (studioUrl.length > 4000) {
  }

  return studioUrl;
};
