import { SWAN_STYLE_KEY_MAP } from "@vp/swan";

export * from "./cart/add-to-cart-button";
export * from "./cart/addToCartOverlay";
export * from "./colors/colorOptions";
export * from "./edit/edit-in-studio-link";
export * from "./edit/editButton";
export * from "./edit/editIcon";
export * from "./edit/editLink";
export * from "./navigation/redirect";
export * from "./pricing/exactPrice";
export * from "./pricing/startingAtPrice";
export * from "./quantity/quantitySelector";
export * from "./tiles/common";
export * from "./hooks/useFocus";

export const RecommendationsSwanStyles = [
  SWAN_STYLE_KEY_MAP.button,
  SWAN_STYLE_KEY_MAP.icon,
  SWAN_STYLE_KEY_MAP.popover,
  SWAN_STYLE_KEY_MAP.selectionSet,
  SWAN_STYLE_KEY_MAP.spinner,
  SWAN_STYLE_KEY_MAP.standardTile
];
