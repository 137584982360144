import { Button, Spinner } from "@vp/swan";
import { JSX, useState } from "react";
import { useAuthContext } from "../../../auth/context";
import {
  Events,
  ProductAddedToCartEvent,
  publish,
} from "../../recommendations-common";
import { usePageContext, useSiteContext } from "../../recommendations-context";
import {
  LocalizedText,
  Texts,
  localizeText,
} from "../../recommendations-localization";
import { Offer } from "../../recommendations-types";
import { addToCart } from "../clients/patch/client";

interface AddToCartButtonProps {
  offer: Offer;
  onProductAddedToCart: (workId?: string) => void;
  onProductAddToCartError?: (errMsg: string) => void;
  onProductAddToCartPermanentError?: () => void;
}

export const AddToCartButton = (props: AddToCartButtonProps): JSX.Element => {
  const auth = useAuthContext();
  const siteContext = useSiteContext();
  const pageContext = usePageContext();
  const [clicked, setClicked] = useState(false);
  const [errorCount, setErrorCount] = useState(0);

  const offer = props.offer;

  return (
    <>
      <Button
        skin="primary"
        width="full-width"
        style={{ whiteSpace: "unset" }}
        onClick={async () => {
          if (clicked) {
            return;
          }
          const initTime = Date.now();
          try {
            setClicked(true);
            const response = await addToCart(
              siteContext.tenant,
              siteContext.locale,
              auth.customerId,
              offer.productKey,
              offer.productVersion,
              offer.productOptions,
              offer.mpvId,
              offer.title,
              offer.quantity,
              offer.prices.availablePrices.map((x) => x.quantity),
              offer.localizedOptions,
              offer.hasQuantitySelectionPage,
              offer.isAccessory,
              auth.authorizationHeader,
              offer.imageUrl,
              offer.documentUrl,
            );

            publish<ProductAddedToCartEvent>(
              Events.ProductAddedToCard,
              pageContext.page,
              {
                offer: offer,
                workId: response.workId,
                duration: Date.now() - initTime,
              },
            );

            props.onProductAddedToCart();
          } catch (error) {
            if (props.onProductAddToCartError) {
              props.onProductAddToCartError(
                errorCount < 3
                  ? Texts.AddToCartError
                  : Texts.AddToCartRepeatedError,
              );
            }
            if (errorCount >= 3 && props.onProductAddToCartPermanentError) {
              props.onProductAddToCartPermanentError();
            }
            setErrorCount((errorCount) => errorCount + 1);
          } finally {
            setClicked(false);
          }
        }}
      >
        {clicked ? (
          <Spinner
            accessibleText={localizeText(
              Texts.AddingToCart,
              siteContext.locale,
            )}
            size="tiny"
            darkMode
          />
        ) : (
          <LocalizedText text={Texts.AddToCart} />
        )}
      </Button>
    </>
  );
};
