import { Column, GridContainer, Row } from "@vp/swan";
import { JSX } from "react";
import { Recommendations } from "../../recommendations-types";
import { ColumnCountContextProvider } from "./columnCountContext";
import { OfferGroupContainer } from "./offer-group-container";
import { TileFactory } from "./offer-tile";

export type PlacementProps = {
  recommendations: Recommendations;
  tileFactory: TileFactory;
};

export function Placement(props: PlacementProps): JSX.Element {
  return (
    <GridContainer>
      <Row>
        <Column span={12}>
          {props.recommendations.offersGroups.map((offerGroup) => (
            <ColumnCountContextProvider
              key={offerGroup.trackingId}
              displayConfiguration={offerGroup.displayConfiguration}
            >
              <OfferGroupContainer
                offerGroup={offerGroup}
                tileFactory={props.tileFactory}
              />
            </ColumnCountContextProvider>
          ))}
        </Column>
      </Row>
    </GridContainer>
  );
}
