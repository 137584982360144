import dadk from "./data/da-dk.json";
import deat from "./data/de-at.json";
import dech from "./data/de-ch.json";
import dede from "./data/de-de.json";
import enau from "./data/en-au.json";
import enca from "./data/en-ca.json";
import engb from "./data/en-gb.json";
import enie from "./data/en-ie.json";
import enin from "./data/en-in.json";
import ennz from "./data/en-nz.json";
import ensg from "./data/en-sg.json";
import enus from "./data/en-us.json";
import eses from "./data/es-es.json";
import esus from "./data/es-us.json";
import fifi from "./data/fi-fi.json";
import frbe from "./data/fr-be.json";
import frca from "./data/fr-ca.json";
import frch from "./data/fr-ch.json";
import frfr from "./data/fr-fr.json";
import itch from "./data/it-ch.json";
import itit from "./data/it-it.json";
import jajp from "./data/ja-jp.json";
import nbno from "./data/nb-no.json";
import nlbe from "./data/nl-be.json";
import nlnl from "./data/nl-nl.json";
import ptpt from "./data/pt-pt.json";
import svse from "./data/sv-se.json";

type Locale = string;
export type LocalizationKey = string;
export type LocalizedText = string;
export type LocalizedTextWithContext = Record<LocalizationKey, LocalizedText>;

export type Localizations = Record<
  LocalizationKey,
  LocalizedText | LocalizedTextWithContext
>;

export default {
  "da-dk": dadk,
  "de-at": deat,
  "de-de": dede,
  "de-ch": dech,
  "en-au": enau,
  "en-ca": enca,
  "en-gb": engb,
  "en-ie": enie,
  "en-in": enin,
  "en-nz": ennz,
  "en-sg": ensg,
  "en-us": enus,
  "es-es": eses,
  "es-us": esus,
  "fi-fi": fifi,
  "fr-be": frbe,
  "fr-ca": frca,
  "fr-ch": frch,
  "fr-fr": frfr,
  "it-ch": itch,
  "it-it": itit,
  "ja-jp": jajp,
  "nb-no": nbno,
  "nl-be": nlbe,
  "nl-nl": nlnl,
  "pt-pt": ptpt,
  "sv-se": svse,
} as unknown as Record<Locale, Localizations>;
