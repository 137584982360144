import { Carousel, CarouselProps } from "@vp/swan";
import { useEffect, useRef, useState } from "react";

type ElevatedCarouselProps = Omit<
  CarouselProps,
  "arrowVerticalOffset" | "arrowVerticalPosition"
>;

export function ElevatedCarousel(props: ElevatedCarouselProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [arrowPosition, setArrowPosition] = useState<string | undefined>(
    undefined,
  );

  const { children, ...otherProps } = props;

  useEffect(() => {
    if (!ref?.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      if (ref?.current) {
        setArrowPosition(`${calculateArrowPosition(ref.current)}px`);
      }
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div ref={ref}>
      <Carousel {...otherProps} arrowVerticalPosition={arrowPosition}>
        {children}
      </Carousel>
    </div>
  );
}

function calculateArrowPosition(carouselElem: HTMLElement) {
  const carouselRect = carouselElem.getBoundingClientRect();
  const tallestImageRect = getTallestTileImageRect(carouselElem);
  return tallestImageRect.top - carouselRect.top + tallestImageRect.height / 2;
}

function getTallestTileImageRect(carouselElem: HTMLElement) {
  const tiles = carouselElem.querySelectorAll(".swan-standard-tile-image");

  return Array.from(tiles).reduce(
    (tallestRect, tile) => {
      const rect = tile.getBoundingClientRect();
      return rect.height > tallestRect.height ? rect : tallestRect;
    },
    { height: 0 } as DOMRectReadOnly,
  );
}
