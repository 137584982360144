import { useRef } from "react"

export const useFocus = () => {
  const ref = useRef<HTMLElement>(null)

  const focus = () => {
    setTimeout(() => {
      ref.current?.focus();
    }, 200); // the timeout is needed because modal closes with a fade-in animation
  };

  return [ref, focus] as const
}