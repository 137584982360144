export const Texts = {
  AddToCart: "AddToCart",
  AddToCartError: "AddToCartError",
  AddToCartRepeatedError: "AddToCartRepeatedError",
  AddedToCart: "AddedToCart",
  AddingToCart: "AddingToCart",
  Edit: "Edit",
  Loading: "Loading",
  Quantity: "Quantity",
  RelatedAccessories: "RelatedAccessories",
  CrossSellStudioCta: "CrossSellStudioCta",
  CarouselPreviousSlide: "CarouselPreviousSlide",
  CarouselNextSlide: "CarouselNextSlide",
  ColorSelection: "ColorSelection",
};
