import { useScreenClass } from "@vp/swan";
import React, { JSX, PropsWithChildren, useContext } from "react";

type ILookAndFeelContext = {
  showTitle: boolean;
  isLargeOrMediumScreen: boolean;
  imageWidth: number | undefined;
};

export const LookAndFeelContext = React.createContext<
  ILookAndFeelContext | undefined
>(undefined);

type LookAndFeelContextProviderProps = {
  imageWidth: number | undefined;
};

export function LookAndFeelContextProvider(
  props: PropsWithChildren<LookAndFeelContextProviderProps>,
): JSX.Element {
  // const screenClass = useScreenClass();
  const screenClass = "lg" as ReturnType<typeof useScreenClass>;

  const context: ILookAndFeelContext = {
    // TODO: consider sending this information from POE.
    showTitle: true,
    isLargeOrMediumScreen:
      screenClass === "xl" || screenClass === "lg" || screenClass === "md",
    imageWidth: props.imageWidth,
  };

  return (
    <LookAndFeelContext.Provider value={context}>
      {props.children}
    </LookAndFeelContext.Provider>
  );
}

export function useLookAndFeelContext(): ILookAndFeelContext {
  const context = useContext(LookAndFeelContext);
  if (context === undefined) {
    throw new Error(
      "useLookAndFeelContext must be used with LookAndFeelContextProvider",
    );
  }
  return context;
}
