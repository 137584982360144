import React, { JSX, PropsWithChildren, useContext } from "react";

export type PageContext = {
  page: string;
  sourceMpvId?: string; // TODO: it could make sense to have this together with parent work id
};

const ThePageContext = React.createContext<PageContext | undefined>(undefined);

export function PageContextProvider(
  props: PropsWithChildren<PageContext>,
): JSX.Element {
  const { children, ...otherProps } = props;
  return (
    <ThePageContext.Provider value={{ ...otherProps }}>
      {children}
    </ThePageContext.Provider>
  );
}

export function usePageContext(): PageContext {
  const context = useContext(ThePageContext);
  if (context === undefined) {
    throw new Error("usePageContext must be used with PageContextProvider");
  }
  return context;
}
