import { useLookAndFeelContext } from "../../../recommendations-context";
import {
  AddToCartButton,
  ColorOptions,
  ExactPrice,
  OfferTileAddedToCartOverlay,
  OfferTileColorsProps,
  OfferTileCtaProps,
  OfferTilePriceProps,
  QuantitySelector,
  QuantitySelectorProps,
  withCtaTile,
} from "../../../recommendations-offer-tiles-common";

function NonDesignableOfferTileCta(props: OfferTileCtaProps) {
  return (
    <AddToCartButton
      offer={props.offer}
      onProductAddedToCart={props.onSuccess}
      onProductAddToCartError={props.onError}
      onProductAddToCartPermanentError={props.onPermanentError}
    />
  );
}

function NonDesignableOfferTileColors(props: OfferTileColorsProps) {
  const lookAndFeelContext = useLookAndFeelContext();

  return (
    <ColorOptions
      offer={props.offer}
      imageWidth={lookAndFeelContext.imageWidth}
    />
  );
}

export const NonDesignableOfferTile = withCtaTile(
  "NonDesignableOfferTile",
  NonDesignableOfferTileCta,
  null,
  null,
  OfferTileAddedToCartOverlay,
  NonDesignableOfferTileColors,
  (props: OfferTilePriceProps) => <ExactPrice {...props} />,
  (props: QuantitySelectorProps) => <QuantitySelector {...props} />,
);
