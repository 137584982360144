import DOMPurify from "dompurify";
import localizations, {
  Localizations,
  LocalizedTextWithContext,
} from "./localizations";

export const localizeText = (key: string, locale: string): string => {
  try {
    const localeKey = locale.toLowerCase();
    const localizedText =
      key in localizations[localeKey]
        ? getLocalizedText(key, localizations[localeKey])
        : getLocalizedTextWithContext(key, localizations[localeKey]);
    return DOMPurify.sanitize(localizedText);
  } catch (error) {
    return "";
  }
};

const getLocalizedText = (
  key: string,
  localizations: Localizations,
): string => {
  const value = localizations[key];
  return typeof value === "string" ? value : "";
};

const getLocalizedTextWithContext = (
  key: string,
  localizations: Localizations,
): string => {
  const localization = Object.values(localizations)
    .filter((t) => typeof t === "object")
    .find((t) => Object.keys(t).includes(key));

  return localization ? (localization as LocalizedTextWithContext)[key] : "";
};
