import React, { JSX } from "react";
import {
  Offer,
  OfferAction,
  OfferGroup,
  OfferType,
} from "../../recommendations-types";
import { EditInStudioAddToCartOfferTile } from "./offer-tiles/edit-in-studio-add-to-cart";
import { EditUpdateInStudioAddToCartOfferTile } from "./offer-tiles/edit-update-in-studio-add-to-cart";
import { MustEditInStudioOfferTile } from "./offer-tiles/must-edit-in-studio";
import { NonDesignableOfferTile } from "./offer-tiles/non-designable";
import { StartDesigningOfferTile } from "./offer-tiles/start-designing";

export type TileFactory = {
  createTile(offer: Offer, offerGroup: OfferGroup): JSX.Element | undefined;
};

type OfferTileProps = {
  offer: Offer;
  offerGroup: OfferGroup;
  tileFactory: TileFactory;
};

const RealOfferTile = (props: OfferTileProps): JSX.Element => {
  const tile = props.tileFactory.createTile(props.offer, props.offerGroup);
  if (!tile) {
    throw new Error(
      `cannot determine offer tile for offer: ${JSON.stringify(props.offer)}`,
    );
  }

  return tile;
};

export const OfferTile = React.memo(RealOfferTile, (prevProps, nextProps) => {
  return (
    JSON.stringify(changeableOfferTileProps(prevProps)) ===
    JSON.stringify(changeableOfferTileProps(nextProps))
  );
});

// This function returns the values for the props that can be changed
// while a user interacts with recommendations. The following list
// explains the reason for changes to these props:
//   - trackingId: a new request to the backend is made
//   - quantity: a QuantityChanged event is received
//   - productOptions: a ColorChanged event is received
//   - imageUrl: a ColorChanged event is received
function changeableOfferTileProps(props: Readonly<OfferTileProps>) {
  return [
    props.offer.trackingId,
    props.offer.quantity,
    props.offer.productOptions,
    props.offer.imageUrl,
  ];
}

export class DefaultTileFactory implements TileFactory {
  createTile(offer: Offer, offerGroup: OfferGroup): JSX.Element | undefined {
    if (
      offer.offerType === OfferType.Image ||
      offer.offerType === OfferType.SmartAssets
    ) {
      return <StartDesigningOfferTile offer={offer} />;
    }

    if (offer.offerType === OfferType.NonDesignable) {
      return <NonDesignableOfferTile offer={offer} />;
    }

    if (offer.offerType === OfferType.DocumentMatching) {
      if (offerGroup.actions[offer.offerType] === OfferAction.EditInStudio) {
        return <MustEditInStudioOfferTile offer={offer} />;
      } else if (
        offerGroup.actions[offer.offerType] === OfferAction.AddToCart ||
        offerGroup.actions[offer.offerType] === OfferAction.EditInPage
      ) {
        return <EditInStudioAddToCartOfferTile offer={offer} />;
      } else if (
        offerGroup.actions[offer.offerType] ===
        OfferAction.EditUpdateInStudioAddToCart
      ) {
        return <EditUpdateInStudioAddToCartOfferTile offer={offer} />;
      }
    }

    return undefined;
  }
}

export class NewEditFlowTileFactory implements TileFactory {
  createTile(offer: Offer, offerGroup: OfferGroup) {
    return new DefaultTileFactory().createTile(offer, offerGroup);
  }
}
