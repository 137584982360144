import {
  CarouselSlide,
  Column,
  GridContainer,
  H3,
  Row,
  // useIsScreenClassInitialized,
  // useScreenClass,
} from "@vp/swan";
import React, { JSX, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useIntersectionObserver } from "usehooks-ts";
import {
  Events,
  ProductListViewedEvent,
  publish,
} from "../../recommendations-common";
import {
  useLookAndFeelContext,
  usePageContext,
  useSiteContext,
} from "../../recommendations-context";
import { localizeText, Texts } from "../../recommendations-localization";
import { Layout, Offer, OfferGroup } from "../../recommendations-types";
import { useColumnCount } from "./columnCountContext";
import { ElevatedCarousel } from "./elevatedCarousel";
import { OfferTile, TileFactory } from "./offer-tile";

type SafeOfferTileProps = {
  offer: Offer;
};

function SafeOfferTile(props: React.PropsWithChildren<SafeOfferTileProps>) {
  return (
    <ErrorBoundary
      fallback={<></>}
      onError={(error: Error, info: React.ErrorInfo) => {}}
    >
      {props.children}
    </ErrorBoundary>
  );
}

type OfferGroupContainerProps = {
  offerGroup: OfferGroup;
  tileFactory: TileFactory;
};

// TODO: columnCount must have a value such that 12 is divisible by that value.
//   We should enforce this restriction somehow (even all the way to POET).
function GridOfferGroupContainer(
  props: OfferGroupContainerProps & { isVisible: boolean },
) {
  const siteContext = useSiteContext();
  const pageContext = usePageContext();

  useEffect(() => {
    if (props.isVisible) {
      publish<ProductListViewedEvent>(
        Events.ProductListViewed,
        pageContext.page,
        {
          offerGroupTrackingId: props.offerGroup.trackingId,
          offers: props.offerGroup.offers,
          set: 1,
        },
      );
    }
  }, [props.isVisible, siteContext.tenant, JSON.stringify(props.offerGroup)]);

  return (
    <div className="recommendations-group-grid">
      <GridContainer>
        <Row component="ul">
          {props.offerGroup.offers.map((offer: Offer) => (
            <SafeOfferTile key={offer.trackingId} offer={offer}>
              <Column
                component="li"
                span={columnSpan(
                  props.offerGroup.displayConfiguration.columnCount,
                )}
                spanSm={columnSpan(
                  props.offerGroup.displayConfiguration.smallColumnCount,
                )}
                spanXs={columnSpan(
                  props.offerGroup.displayConfiguration.extraSmallColumnCount,
                )}
              >
                <OfferTile
                  offer={offer}
                  offerGroup={props.offerGroup}
                  tileFactory={props.tileFactory}
                />
              </Column>
            </SafeOfferTile>
          ))}
        </Row>
      </GridContainer>
    </div>
  );
}

function CarouselOfferGroupContainer(
  props: OfferGroupContainerProps & { isVisible: boolean },
) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const columnCount = useColumnCount();
  const siteContext = useSiteContext();
  const pageContext = usePageContext();

  useEffect(() => {
    if (!props.isVisible) {
      return;
    }

    const computeSet = (): number => {
      return Math.ceil(currentSlide / columnCount) + 1;
    };

    const offersToTrack = props.offerGroup.offers.slice(
      currentSlide,
      currentSlide + columnCount,
    );

    publish<ProductListViewedEvent>(
      Events.ProductListViewed,
      pageContext.page,
      {
        offerGroupTrackingId: props.offerGroup.trackingId,
        offers: offersToTrack,
        set: computeSet(),
      },
    );
  }, [
    props.isVisible,
    columnCount,
    currentSlide,
    props.offerGroup.trackingId,
    JSON.stringify(props.offerGroup.offers),
    siteContext.tenant,
    pageContext.page,
  ]);

  return (
    <div className="recommendations-group-carousel">
      <ElevatedCarousel
        arrows
        skin="standard"
        infinite={false}
        // See Note on https://vista.design/swan/components/carousel/?tab=Usage#Peek
        // 0.45 shows 45% of the next slide.
        slidesToShow={columnCount + 0.45}
        slidesToScroll={columnCount}
        waitForAnimate={false}
        accessibleTextForDots={[]}
        accessibleTextForNext={localizeText(
          Texts.CarouselNextSlide,
          siteContext.locale,
        )}
        accessibleTextForPrevious={localizeText(
          Texts.CarouselPreviousSlide,
          siteContext.locale,
        )}
        afterChange={(slide: number) => {
          setCurrentSlide(Math.round(slide));
        }}
        gridGutters
      >
        {props.offerGroup.offers.map((offer: Offer, idx: number) => (
          // XXX: we do not use offer.trackingId at this level, as it seems
          //   the Carousel component rewrites its children's keys.
          //.  In theory it should respect any existing keys, but the next issue
          //.  might be the responsible one for not doing so:
          //.  https://github.com/akiran/react-slick/issues/2005
          //.  As a workaround we use offer.trackingId one level down.
          <SafeOfferTile key={idx} offer={offer}>
            <CarouselSlide key={offer.trackingId} style={{ width: "100%" }}>
              <OfferTile
                offer={offer}
                offerGroup={props.offerGroup}
                tileFactory={props.tileFactory}
              />
            </CarouselSlide>
          </SafeOfferTile>
        ))}
      </ElevatedCarousel>
    </div>
  );
}

function EmptyOfferGroupContainer(
  props: Omit<OfferGroupContainerProps, "tileFactory"> & { isVisible: boolean },
) {
  const siteContext = useSiteContext();
  const pageContext = usePageContext();

  useEffect(() => {
    if (props.isVisible && props.offerGroup.offers.length === 0) {
      publish<ProductListViewedEvent>(
        Events.ProductListViewed,
        pageContext.page,
        {
          offerGroupTrackingId: props.offerGroup.trackingId,
          offers: [],
          set: 1,
          discardedOffers: true,
        },
      );
    }
  }, [props.isVisible, siteContext.tenant, JSON.stringify(props.offerGroup)]);

  return <></>;
}

export function OfferGroupContainer(
  props: OfferGroupContainerProps,
): JSX.Element {
  // const screenClass = useScreenClass();
  // const screenClass = "lg" as ReturnType<typeof useScreenClass>;
  // const [sanitizedOfferGroup, setSanitizedOfferGroup] = useState<
  //   OfferGroup | undefined
  // >({
  //   ...props.offerGroup,
  //   offers: truncateOffersUsingMinimumOffersToDisplay(
  //     screenClass,
  //     props.offerGroup,
  //   ),
  // });

  // const screenClassInitialized = useIsScreenClassInitialized();

  // useEffect(() => {
  //   if (screenClassInitialized) {
  //     setSanitizedOfferGroup({
  //       ...props.offerGroup,
  //       offers: truncateOffersUsingMinimumOffersToDisplay(
  //         screenClass,
  //         props.offerGroup,
  //       ),
  //     });
  //   }
  // }, [JSON.stringify(props.offerGroup), screenClass, screenClassInitialized]);

  // if (!sanitizedOfferGroup) {
  //   return <></>;
  // }

  return (
    <SanitizedOfferGroupContainer
      offerGroup={props.offerGroup}
      tileFactory={props.tileFactory}
    />
  );
}

function SanitizedOfferGroupContainer(props: OfferGroupContainerProps) {
  // TODO: consider using threshold instead of (or in addition to) rootMargin.
  //   But then we need to compute the right threshold, which will depend on
  //   the screen size and the number of total offers. For instance, the logo
  //   maker download page is an extreme case where lots of products are shown.
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
    rootMargin: "-100px",
  });

  if (props.offerGroup.offers.length === 0) {
    return (
      <div ref={ref}>
        <EmptyOfferGroupContainer
          offerGroup={props.offerGroup}
          isVisible={isIntersecting}
        />
      </div>
    );
  }

  const shouldRenderCarousel =
    props.offerGroup.displayConfiguration.layout === Layout.Carousel;

  return (
    <div ref={ref}>
      <div className="recommendations-group">
        <OfferGroupTitle title={props.offerGroup.displayConfiguration.title} />
        {shouldRenderCarousel ? (
          <CarouselOfferGroupContainer
            offerGroup={props.offerGroup}
            tileFactory={props.tileFactory}
            isVisible={isIntersecting}
          />
        ) : (
          <GridOfferGroupContainer
            offerGroup={props.offerGroup}
            tileFactory={props.tileFactory}
            isVisible={isIntersecting}
          />
        )}
      </div>
    </div>
  );
}

type OneThroughTwelve = React.ComponentProps<typeof Column>["span"];

const columnSpan = (columnCount: number): OneThroughTwelve =>
  (12 / columnCount) as OneThroughTwelve;

type OfferGroupTitleProps = {
  title: string;
};

function OfferGroupTitle(props: OfferGroupTitleProps) {
  const lookAndFeelContext = useLookAndFeelContext();
  return lookAndFeelContext.showTitle ? (
    <div className="recommendations-group-title">
      <H3 fontSize="x2large" marginTop={3} marginBottom={6}>
        {props.title}
      </H3>
    </div>
  ) : (
    <></>
  );
}

// function truncateOffersUsingMinimumOffersToDisplay(
//   screenClass: string,
//   offerGroup: OfferGroup,
// ): Offer[] {
//   return offerGroup.offers.length >= minOffersToDisplay(offerGroup, screenClass)
//     ? offerGroup.offers
//     : [];
// }

// function minOffersToDisplay(offerGroup: OfferGroup, screenClass: string) {
//   if (offerGroup.displayConfiguration.minOffersToDisplay) {
//     switch (screenClass) {
//       case "xl":
//       case "lg":
//       case "md":
//         return offerGroup.displayConfiguration.minOffersToDisplay.default;
//       case "sm":
//         return offerGroup.displayConfiguration.minOffersToDisplay.small;
//       case "xs":
//         return offerGroup.displayConfiguration.minOffersToDisplay.extraSmall;
//     }
//   }

//   return 0;
// }
