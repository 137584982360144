import qs from "qs";
import { LocalizedOption } from "../../../recommendations-types";

type PatchResponse = {
  workId?: string;
  documentUrl?: string;
};

export async function addToCart(
  tenant: string,
  locale: string,
  customerId: string,
  productKey: string,
  productVersion: number,
  productOptions: { [key: string]: string },
  mpvId: string,
  displayName: string,
  quantity: number,
  availableQuantities: number[],
  localizedOptions: { [key: string]: LocalizedOption },
  hasQuantitySelectionPage: boolean,
  isAccessory: boolean,
  authorizationHeader: string,
  imageUrl?: string,
  documentUrl?: string,
  parentWorkId?: string,
): Promise<PatchResponse> {
  const url =
    `https://patch.personalization.vpsvc.com/api/v1/addToCart/${tenant}/${locale}/${customerId}` +
    qs.stringify(
      {
        requestor: "recommendations-stack",
      },
      { addQueryPrefix: true },
    );
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: authorizationHeader,
    },
    body: JSON.stringify({
      productKey: productKey,
      productVersion: productVersion,
      productOptions: productOptions,
      mpvId: mpvId,
      productDisplayName: displayName,
      quantity: quantity,
      availableQuantities,
      localizedOptions,
      ...(imageUrl && { imageUrl: imageUrl }),
      ...(documentUrl && { documentUrl: documentUrl }),
      ...(parentWorkId && { parentWorkEntityId: parentWorkId }),
      hasQuantitySelectionPage: hasQuantitySelectionPage,
      isAccessory: isAccessory,
    }),
  });

  return response.ok ? await response.json() : Promise.reject(response);
}
